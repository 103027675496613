#dashboard {
  color: snow;
  text-decoration: none;
  margin-left: 100px;
}

#icon{
  margin-right: 85px;
}

#btn-1 {
  margin: 20px;
  margin-left: 0%;
}

#btn-2 {
  margin: 20px;
  margin-right: 0%;
  float: right;
}

#bottom {
  margin: 0%;
}

#head {
  margin: 20px;
  margin-right: 10px;
  justify-items: flex-end;
  display: flex;
  float: right;
  margin-top: 10px;
}
#para {
  text-align: center;
  margin: 10px;
}

#button {
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 15px;
}

#topEventsSelect {
  margin-right: 450px;
  margin-top: 5px;
}

#button{
  text-decoration: none;
  color: white;
}

#event:hover{
  cursor: pointer;
}

#scan_out{
  margin-left: 10px;
}

#menu-appbar .MuiButtonBase-root:hover a{
  color:var(--bs-body-color)!important;  
}



