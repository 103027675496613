
#user_profile {
  display: block;
  margin: 0 auto;
  width: 30%;
  border-radius: 10%;
}

@media print
{    
    .no-print, .no-print *
    {
        display: none !important;
    }
}