.mlg--logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 30%;
}

.container {
    display: flex;
    justify-content: center;
    height: 90vh;
    align-items: center;
}

.mlg--Heading {
  text-align: center;
  font-weight: 700;
  font-size: 40px;
  font-family: Merriweather ;
  color: #3c4858;
}

.mlg--subHeading {
  text-align: center;
  font-weight: 550;
  font-size: 24px;
  margin-bottom: 25px;
  font-family: Merriweather;
  color: #3c4858;
  margin-bottom: 50px;
}


